import {createRouter , createWebHashHistory } from 'vue-router'
import isAuthenticatedLogin from '../auth/authLogin'
import isAuthenticated from '../auth/authGuard'

const routes = [
    {     
        path: "/login",
        name:'Login',
        beforeEnter: [isAuthenticatedLogin],
        component:()=> import ('../Login.vue'),
    },
    {
        path: "/home",
        name: 'Home',
        beforeEnter:[isAuthenticated],
        component:()=>import ('../views/Home.vue'),
        children:[
            {
                path:'/dashboard',
                name:'Dashboard',
                component:()=>import('../components/Dashboard.vue'),
            },
            {
                path:'/ordenes',
                name:'Orders',
                component:()=>import('../components/Orders.vue'),
            },
            {
                path:'/productos',
                name:'Products',
                component:()=>import('../components/Products.vue'),
            },
            {
                path:'/cargos',
                name:'Charges',
                component:()=>import('../components/Extracharges.vue'),
            },
            {
                path: '/clientes',
                name:'Clients',
                component:()=>import('../components/Clients.vue')
            },
            {
                path:'/existencia',
                name:'Existence',
                component:()=>import('../components/ProductsExistence.vue')
            },
            {
                path:'/incentives',
                name:'Incentives',
                component:()=>import('../components/Incentives.vue')
            },
            {
                path:'/ordenes/:orderId',
                name:'OrderDetails',
                component:()=>import('../components/OrderDetails.vue'),
                props: ( route ) => {
                    const orderId = Number( route.params.orderId );
                    
                    return isNaN( orderId ) ? { orderId: localStorage.getItem('orderId') } : { orderId }
                }
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        beforeEnter: [isAuthenticatedLogin],
        redirect: { name: 'Dashboard' }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router;