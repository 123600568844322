import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import FomanticUI from 'vue-fomantic-ui'
import 'fomantic-ui-css/semantic.min.css'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

createApp(App)
.use( router )
.use(FomanticUI)
.use(Toast,{
	position: POSITION.TOP_CENTER,
	toastClassName: "toast",
})
.component('VueDatePicker', VueDatePicker)
.mount('#app')
